<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href='/monitor'/>
        </ion-buttons>
        <ion-title v-if="vitalType" class="ion-text-capitalize vital-title">
          {{ vitalTitle }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-loading :is-open="loading" message="Loading..." :duration="20000" cssClass="medium-loading-container"
          @didDismiss="loading=false"/>
      <ion-toast position="top" color="warning" :is-open="warning" :message="warningMessage" :duration="5000"
                 @didDismiss="warning=false"/>
      <ion-toast position="top" color="success" :is-open="success" :message="successMessage" :duration="5000"
                 @didDismiss="success=false"/>
      <div v-if="vitalType && initialized" class="vitals-container">
        <ion-segment :value="timeWindow" @ionChange="segmentChange">
          <ion-segment-button v-if="narrowView" value="Day">
            <ion-label>
              Day
            </ion-label>
          </ion-segment-button>
          <ion-segment-button value="Week">
            <ion-label>
              Week
            </ion-label>
          </ion-segment-button>
          <ion-segment-button v-if="!narrowView" value="Month">
            <ion-label>
              Month
            </ion-label>
          </ion-segment-button>
        </ion-segment>
        <div class="vital-title-container" :class="{hide: hideTitle}">
          <div v-if="vitalType === 'blood_pressure'">
            <ion-grid class="blood-pressure-grid">
              <ion-row class="ion-text-uppercase vital-range-title vital-first-title">
                <ion-col>
                  <span class="vital-point vital-point-circle" :style="{backgroundColor: brandDanger}"/>
                  Systolic
                </ion-col>
                <ion-col>
                  <span class="vital-point vital-point-rectangle" :style="{backgroundColor: brandBlack}"/>
                  Diastolic
                </ion-col>
              </ion-row>
              <ion-row v-if="aggregates">
                <ion-col>
                  <span class="vital-range-value">
                    {{ getAggregate('min', 'systolic') }}
                  </span>
                  <span class="vital-range-value">
                    –{{ getAggregate('max', 'systolic') }}
                  </span>
                </ion-col>
                <ion-col>
                  <span class="vital-range-value">
                    {{ getAggregate('min', 'diastolic') }}
                  </span>
                  <span class="vital-range-value">
                    –{{ getAggregate('max', 'diastolic') }}
                  </span>
                  <span class="vital-range-unit">
                    {{ vitalUnit }}
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div v-else>
            <div class="ion-text-uppercase vital-range-title vital-first-title">
              Range
            </div>
            <div v-if="aggregates">
              <span class="vital-range-value">
                {{ getAggregate('min') }}
              </span>
              <span class="vital-range-value">
                –{{ getAggregate('max') }}
              </span>
              <span class="vital-range-unit">
                {{ vitalUnit }}
              </span>
            </div>
          </div>
          <div class="vital-range-title vital-range-date">
            {{ timeRange }}
          </div>
        </div>
        <VitalsChart :vitalType="vitalType" :timeWindow="timeWindow" :startDate="startDate" :endDate="endDate"
          :vitalUnit="vitalUnit" :narrowView="narrowView" :rtm="rtm" @setAggregates="setAggregates"
          @hideTitle="setHideTitle" :key="vitalsChartKey"/>
        <ion-card v-if="aggregates && vitalType !== 'blood_pressure'" class="vital-average-card">
          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col class="vital-range-title">
                  Average
                </ion-col>
                <ion-col class="ion-text-right">
                  <span class="vital-range-value">
                    {{ getAggregate('avg') }}
                  </span>
                  <span class="vital-range-unit">
                    {{ vitalUnit }}
                  </span>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
        <ion-button v-if="rtm" @click="showRTMVitalFormModal=true" expand="block">
          Add an update
        </ion-button>
        <VitalsAlerts v-if="!rtm" :vitalType="vitalType" :timeWindow="timeWindow"
                      :startDate="startDate" :endDate="endDate" :narrowView="narrowView"/>
      </div>
    </ion-content>
    <RTMVitalFormModal v-if="rtm" :showModal="showRTMVitalFormModal" :location="vitalType" @dismissModal="dismissModal"/>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  IonBackButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonLoading,
  IonToast,
} from '@ionic/vue'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { pathToClinicalIDs, brandDanger, brandBlack, painLocationClinicalIDs } from '@/utils/vitals'
import { getDayDifference } from '@/utils/date'
import VitalsChart from '@/components/VitalsChart'
import VitalsAlerts from '@/components/VitalsAlerts'
import RTMVitalFormModal from '@/components/RTMVitalFormModal'
import {
  PATIENT_RPM_DEVICES_CHECK,
  PATIENT_RTM_VITALS_CHECK,
  PATIENT_RTM_VITALS_REQUEST,
  PATIENT_RPM_VITALS_REQUEST,
  PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST
} from '@/store/actions/patient'
import { dismissElement } from '@/utils/overlay'

export default {
  name: 'Vitals',
  setup () {
    return {
      brandDanger, brandBlack,
    }
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonButtons,
    IonBackButton,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonLoading,
    IonToast,
    VitalsChart,
    VitalsAlerts,
    RTMVitalFormModal,
  },
  data () {
    return {
      loading: false,
      aggregates: null,
      timeWindow: 'Week',
      startDate: dayjs().endOf('day').subtract(7, 'days'),
      endDate: dayjs().endOf('day'),
      hideTitle: false,
      initialized: false,
      showRTMVitalFormModal: false,
      warning: false,
      warningMessage: "Form submitted successfully. Continue with other pain locations.",
      success: false,
      successMessage: "All done for today! Don't forget to come back tomorrow.",
      vitalsChartKey: 0,
    }
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'patientRPMVitals',
      'patientRPMDevices',
      'patientRTMVitals',
      'patientPainLocations',
      'patientRPMVitalNotifications',
    ]),
    vitalType () {
      return this.$route.params.vitalType || ''
    },
    rtm () {
      return painLocationClinicalIDs.includes(this.vitalType)
    },
    vitalTitle () {
      return this.rtm ? this.vitalType.split('_').reverse().join(' ') : this.vitalType.replace('_', ' ')
    },
    patientVitals () {
      return this.rtm ? this.patientRTMVitals : this.patientRPMVitals
    },
    devicesAvailable () {
      return !this.loading && this.patientRPMDevices.hasLoadedOnce && this.patientRPMDevices.data.length
    },
    dataAvailable () {
      if (this.rtm) {
        return this.vitalsAvailable
      }
      return this.devicesAvailable && this.vitalsAvailable && this.vitalNotificationsAvailable
    },
    narrowView () {
      return this.vitalType === 'blood_glucose' && this.patientRPMDevices.data.some(
        ({vendor, type}) => vendor === 'dexcom' && type === 'glucose')
    },
    vitalsAvailable () {
      let vitals = this.patientVitals
      return !this.loading && vitals.hasLoadedOnce && this.clinicalIDs.some(
          clinicalID => vitals.data[clinicalID] && vitals.data[clinicalID].length)
    },
    clinicalIDs () {
      return this.rtm ? [this.vitalType] : pathToClinicalIDs[this.vitalType]
    },
    vitalUnit () {
      if (!this.vitalsAvailable || this.rtm) {return ''}
      let clinicalID = this.clinicalIDs[0]
      let vitals = this.patientVitals.data[clinicalID]
      let unit = vitals.data[0].new_unit
      return this.vitalType === 'heart_rate' ? unit.toUpperCase() : unit
    },
    timeRange () {
      // startDate is at the end of previous day, so add 1 to show effective date
      let effectiveStartDate =  this.startDate.add(1, 'days')
      let effectiveStartDateFormat = 'MMM D, YYYY'
      let endDateFormat = 'MMM D, YYYY'
      if (effectiveStartDate.isSame(this.endDate, 'day')) {
        return this.endDate.format(endDateFormat)
      }
      if (effectiveStartDate.isSame(this.endDate, 'month')) {
        effectiveStartDateFormat = 'MMM D'
        endDateFormat = 'D, YYYY'
      } else if (effectiveStartDate.isSame(this.endDate, 'year')) {
        effectiveStartDateFormat = 'MMM D'
        endDateFormat = 'MMM D, YYYY'
      }
      return `${effectiveStartDate.format(effectiveStartDateFormat)} – ${this.endDate.format(endDateFormat)}`
    },
    completedAllFormsToday () {
      return this.rtm ? this.patientPainLocations.data.every(({location}) => this.hasVitalToday(location)) : false
    },
    vitalNotificationsAvailable () {
      if (this.rtm) { return true }
      return !this.loading && this.patientRPMVitalNotifications.hasLoadedOnce
    },
  },
  methods: {
    setAggregates (aggregates) {
      this.aggregates = aggregates
    },
    getAggregate (type, clinicalID) {
      let aggregate
      if (this.vitalType === 'blood_glucose') {
        let aggregates = this.clinicalIDs.map(clinicalID => (this.aggregates[clinicalID] || {})[type]).filter(
          aggregate => aggregate !== null)
        let counts, totalCount
        switch (type) {
          case 'min':
            aggregate = aggregates.length ? Math.min(...aggregates) : null
            break
          case 'max':
            aggregate = aggregates.length ? Math.max(...aggregates) : null
            break
          case 'avg':
            if (aggregates.length < 2) {
              aggregate = aggregates[0]
              break
            }
            counts = this.clinicalIDs.map(clinicalID => this.aggregates[clinicalID].cnt).filter(aggregate => aggregate)
            totalCount = counts.reduce((total, count) => total + count, 0)
            aggregate = aggregates.reduce((sum, average, index) => sum + average * counts[index], 0) / totalCount
            break
        }
      } else {
        aggregate = (this.aggregates[clinicalID || this.clinicalIDs[0]] || {})[type]
      }
      if (aggregate === null || aggregate === undefined) {return ''}
      return parseFloat(aggregate.toFixed(0))
    },
    segmentChange ({detail}) {
      this.timeWindow = detail.value
      this.endDate = dayjs().endOf('day')
      let daysBack = getDayDifference(detail.value)
      this.startDate = this.endDate.subtract(daysBack, 'days')
    },
    setHideTitle (hideTitle) {
      this.hideTitle = hideTitle
    },
    setData (onLeave) {
      if (onLeave) {
        this.initialized = false
        let defaultWindow = this.narrowView ? 'Day' : 'Week'
        this.segmentChange({detail: {value: defaultWindow}})
      } else {
        if (this.narrowView) {
          this.segmentChange({detail: {value: 'Day'}})
        }
        this.initialized = true
      }
    },
    dismissModal (showToast) {
      this.loading = true
      this.showRTMVitalFormModal = false
      this.$store.dispatch(PATIENT_RTM_VITALS_REQUEST, {params: {mobile: true}}).then(() => {
        this.loading = false
        this.vitalsChartKey += 1
        this.segmentChange({detail: {value: 'Week'}})
        if (!showToast) {return}
        if (this.completedAllFormsToday) {
          this.success = true
        } else {
          this.warning = true
        }
      })
    },
    clinicalIDAvailable (clinicalID) {
      return this.vitalsAvailable && this.patientVitals.data[clinicalID].data.length
    },
    hasVitalToday (location) {
      if (!this.clinicalIDAvailable(location)) {
        return false
      }
      let vitals = this.patientVitals.data[location].data
      let lastVital = vitals[vitals.length - 1]
      let now = dayjs()
      let vitalDate = dayjs(lastVital.date)
      return now.isSame(vitalDate, 'day')
    },
  },
  ionViewWillEnter () {
    if (this.dataAvailable) {
      this.setData()
      return
    }
    dismissElement('ion-loading')
    this.loading = true
    let promises = []
    if (this.rtm) {
      promises.push(
        this.$store.dispatch(PATIENT_RTM_VITALS_CHECK, {params: {mobile: true}})
      )
    } else {
      promises.push(
        this.$store.dispatch(PATIENT_RPM_DEVICES_CHECK),
        this.$store.dispatch(PATIENT_RPM_VITALS_REQUEST, {params: {mobile: true}}),
        this.$store.dispatch(PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST, {params: {mobile: true}})
      )
    }
    Promise.all(promises).then(() => {
      this.setData()
      this.loading = false
    })
  },
  ionViewWillLeave () {
    this.loading = false
    this.aggregates = null
    this.hideTitle = false
    this.setData(true)
  },
}
</script>

<style scoped>
.vital-title {
  font-size: 1rem;
}
.vitals-container {
  margin: 10px 25px;
}
.vital-range-title {
  color: var(--ion-color-medium-tint);
  font-weight: 600;
  font-size: 0.7em;
  margin-top: 5px;
}
.vital-first-title {
  margin-top: 15px;
}
.vital-range-date {
  margin-top: 0;
}
.vital-range-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--ion-color-light-contrast);
  display: inline-block;
}
.vital-range-unit {
  padding-left: 0.25em;
  font-weight: 600;
  font-size: 0.8em;
  color: var(--ion-color-medium-tint);
}
.vital-average-card {
  margin: 20px 0;
}
.vital-average-card ion-card-content {
  padding: 0 5px;
}
.vital-average-card ion-row {
  align-items: center;
}
.vital-average-card .vital-range-value {
  font-size: 0.8rem;
  margin-right: 0.1em;
}
.vital-average-card .vital-range-title {
  color: var(--ion-color-light-contrast);
  font-size: 0.8rem;
  margin: 0;
}
.vital-point {
  display: inline-block;
  height: 8px;
  width: 8px;
}
.vital-point-circle {
  border-radius: 50%;
}
.vital-point-rectangle {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.blood-pressure-grid, .blood-pressure-grid ion-col {
  padding: 0;
}
.vital-title-container.hide {
  opacity: 0;
}
</style>
