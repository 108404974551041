<template>
  <ion-loading :is-open="loading" message="Loading..." :duration="20000" cssClass="medium-loading-container"
      @didDismiss="loading=false"/>
  <LineChart v-if="!rendering" :chartData="chartData" :options="chartOptions" class="vital-chart"/>
  <div class="tooltip-vertical-line" :class="{hide: !showTooltip}"/>
</template>

<script>
import { IonLoading } from '@ionic/vue'
import { defineComponent } from 'vue'
import { LineChart } from 'vue-chart-3'
import { dismissElement } from '../utils/overlay'
import { mapGetters } from 'vuex'
import {
  pathToRanges,
  pathToClinicalIDs,
  colors,
  brandGray,
  brandDarkGray,
  brandDanger,
  brandBlack,
} from '../utils/vitals'
import dayjs from 'dayjs'
import axios from 'axios'
import {
  PATIENT_RPM_VITALS_REQUEST,
} from "@/store/actions/patient";

export default defineComponent({
  name: 'VitalsChart',
  emits: [
    'setAggregates',
    'hideTitle',
  ],
  props: [
    'vitalType',
    'timeWindow',
    'startDate',
    'endDate',
    'vitalUnit',
    'rtm',
    'narrowView',
  ],
  components: {
    IonLoading,
    LineChart,
  },
  data () {
    return {
      extendedVitals: {},
      chartData: {},
      chartOptions: {},
      loading: false,
      showTooltip: false,
      rendering: true,
    }
  },
  computed: {
    ...mapGetters([
      'patientRPMVitals',
      'patientRTMVitals',
      'userProfile',
    ]),
    patientVitals () {
      return this.rtm ? this.patientRTMVitals : this.patientRPMVitals
    },
    vitals () {
      let defaultWindow = this.narrowView ? 'Day' : 'Week'
      return this.timeWindow === defaultWindow ? this.patientVitals.data : this.extendedVitals
    },
    hasVitals () {
      return !!Object.keys(this.vitals).length
    },
    clinicalIDs () {
      return this.rtm ? [this.vitalType] : pathToClinicalIDs[this.vitalType]
    },
    xScales () {
      return {
        grid: {
          color: brandGray,
          borderDash: [2, 2],
          tickLength: 20,
          tickBorderDash: [2, 2],
        },
        type: 'linear',
        min: this.convertDateToX(this.startDate),
        max: this.convertDateToX(this.endDate),
        ticks: {
          stepSize: this.timeWindow === 'Day' ? 60 : 24 * 60, // hourly or daily
          autoSkip: false,
          callback: this.xTicksCallback,
        },
      }
    },
    yScales () {
      let {regular, extended} = pathToRanges[this.rtm ? 'pain_location' : this.vitalType]
      let scale = regular
      if (this.hasVitals) {
        let min = Math.floor(Math.min(...this.clinicalIDs.reduce((arr, clinicalID) => [
          ...arr, this.vitals[clinicalID].aggregates.min
        ], [])))
        let max = Math.ceil(Math.max(...this.clinicalIDs.reduce((arr, clinicalID) => [
          ...arr, this.vitals[clinicalID].aggregates.max
        ], [])))
        if (min < regular.min || max > regular.max) {
          scale = extended
        }
      }
      return {
        grid: {
          color: brandGray,
          drawTicks: false,
        },
        position: 'right',
        min: scale.min,
        max: scale.max,
        ticks: {
          stepSize: scale.stepSize,
          autoSkip: false,
        }
      }
    },
  },
  methods: {
    convertDateToX (date) {
      return Math.floor(date.unix() / 60)  // minute resolution
    },
    getVitals () {
      dismissElement('ion-loading')
      this.loading = true
      axios({
        method: 'get',
        url: `/patients/${this.userProfile.role_data.id}/${this.rtm ? 'rtm' : 'rpm'}_vitals/`,
        params: {
          start: this.startDate.format('YYYY-MM-DD'),
          end: this.endDate.format('YYYY-MM-DD'),
          clinical_ids: this.clinicalIDs.join(','),
          mobile: true
        },
      }).then(({data}) => {
        this.extendedVitals = data
        this.render()
        dismissElement('ion-loading')
      })
    },
    setAggregates () {
      let aggregates = this.clinicalIDs.reduce(
        (obj, clinicalID) => ({...obj, [clinicalID]: (this.vitals[clinicalID] || {}).aggregates}), {})
      this.$emit('setAggregates', aggregates)
    },
    getDataset (clinicalID) {
      let data = []
      let pointColors = []
      let pointColor, containsTime, vitalDate, parsedDate, vitalDateFormat
      if (this.hasVitals) {
        this.vitals[clinicalID].data.forEach(({date, float_value, color}) => {
          containsTime = date.includes('T')
          vitalDate = containsTime ? date.slice(0, -6) : date  // remove TZ info to prevent converting to local TZ
          parsedDate = dayjs(vitalDate)
          pointColor = colors[color]
          vitalDateFormat = `MMM D${containsTime ? ', h:mm A' : ''}`
          data.push({
            x: this.convertDateToX(parsedDate),
            y: float_value,
            date: parsedDate.format(vitalDateFormat),
            clinicalID: clinicalID,
          })
          pointColors.push(pointColor)
        })
      }
      let diastolic = clinicalID === 'diastolic'
      let pointBackgroundColor
      if (clinicalID === 'systolic') {
        pointBackgroundColor = brandDanger
      } else if (diastolic) {
        pointBackgroundColor = brandBlack
      } else {
        pointBackgroundColor = pointColors
      }
      let pointStyle = diastolic ? 'rectRot' : 'circle'
      let narrowWeekView = this.narrowView && this.timeWindow === 'Week'
      return {
        data: data,
        borderColor: brandDarkGray,
        borderWidth: narrowWeekView ? 0.5 : 1,
        tension: 0,
        spanGaps: true,
        showLine: true,
        fill: false,
        pointStyle: pointStyle,
        pointRadius: narrowWeekView ? 1.5 : 4,
        pointHoverRadius: 5,
        pointBackgroundColor: pointBackgroundColor,
      }
    },
    getDatasets () {
      let datasets = []
      let combineVitals = this.vitalType === 'blood_glucose'
      this.clinicalIDs.forEach((clinicalID, index) => {
        let dataset = this.getDataset(clinicalID)
        if (!combineVitals || !index) {
          datasets.push(dataset)
        } else {
          datasets[0].data.push(...dataset.data)
        }
      })
      if (combineVitals) {  // line chart needs points to be sorted
        datasets[0].data.sort((a, b) => a.x - b.x)
      }
      return datasets
    },
    xTicksCallback (value, index) {
      switch (this.timeWindow) {
        case 'Month':
          return [3, 10, 17, 24].includes(index) ? `${31-index}d` : null
        case 'Week':
          return [1, 3, 5].includes(index) ? `${7-index}d` : null
        case 'Day':
          switch (index) {
            case 0:
              return '12 AM'
            case 12:
              return '12 PM'
            case 6:
            case 18:
              return '6'
            default:
              return null
          }
      }
    },
    customTooltips ({tooltip, chart}) {
      let tooltipEl = document.getElementById('chartjs-tooltip')
      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        tooltipEl.innerHTML = '<table></table>'
        document.body.appendChild(tooltipEl)
      }
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        this.showTooltip = false
        return
      }
      let position = chart.canvas.getBoundingClientRect()
      let tooltipVerticalLine = document.getElementsByClassName('tooltip-vertical-line')[0]
      tooltipVerticalLine.style.left = `${position.left + window.pageXOffset + tooltip.caretX - 0.5}px`
      tooltipVerticalLine.style.top = `${position.top}px`
      tooltipVerticalLine.style.height = `${position.height - 40}px`
      this.showTooltip = true
      let documentStyle = getComputedStyle(document.body)
      if (tooltip.body) {
        let innerHtml = ''
        let mediumTint = documentStyle.getPropertyValue('--ion-color-medium-tint')
        let titleStyle = `font-weight: 600; font-size: 0.7em; text-align: left; color: ${mediumTint};`
        let lightContrast = documentStyle.getPropertyValue('--ion-color-light-contrast')
        let valueStyle = `font-size: 1.5rem; font-weight: 600; color: ${lightContrast};`
        let pointStyle = 'display: inline-block; height: 8px; width: 8px;'
        let circleStyle = `
          ${pointStyle}
          border-radius: 50%;
          background-color: ${brandDanger};
        `
        let rectangleStyle = `
          ${pointStyle}
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          background-color: ${brandBlack};
        `
        let dataPoints = tooltip.body.map(({lines}) => lines)
        if (this.vitalType === 'blood_pressure') {
          let separatedData = tooltip.dataPoints.reduce((dict, {raw}) => {
            dict[raw.clinicalID].push(raw.y)
            return dict
          }, {systolic: [], diastolic: []})
          let systolic = ''
          if (separatedData.systolic.length > 1) {
            let systolicMin = parseFloat(Math.min(...separatedData.systolic).toFixed(0))
            let systolicMax = parseFloat(Math.max(...separatedData.systolic).toFixed(0))
            systolic += `${systolicMin}–${systolicMax}`
          } else {
            systolic += parseFloat(separatedData.systolic[0].toFixed(0))
          }
          let diastolic = ''
          if (separatedData.diastolic.length > 1) {
            let diastolicMin = parseFloat(Math.min(...separatedData.diastolic).toFixed(0))
            let diastolicMax = parseFloat(Math.max(...separatedData.diastolic).toFixed(0))
            diastolic += `${diastolicMin}–${diastolicMax}`
          } else {
            diastolic += parseFloat(separatedData.diastolic[0].toFixed(0))
          }
          innerHtml += `
            <thead style="${titleStyle}">
              <tr>
                <th>
                  <span style="${circleStyle}"></span>
                  SYSTOLIC
                </th>
                <th>
                  <span style="${rectangleStyle}"></span>
                  DIASTOLIC
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="${valueStyle}">
                  ${systolic}
                </td>
                <td style="${valueStyle}">
                  ${diastolic}
          `
        } else if (dataPoints.length > 1) {
          let dataMin = parseFloat(Math.min(...dataPoints).toFixed(0))
          let dataMax = parseFloat(Math.max(...dataPoints).toFixed(0))
          innerHtml += `
            <thead style="${titleStyle}">
              <tr>
                <th>
                  RANGE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="${valueStyle}">
                  ${dataMin}–${dataMax}
          `
        } else {
          innerHtml += `
            <tbody>
              <tr>
                <td style="${valueStyle}">
                  ${parseFloat(parseFloat(dataPoints[0]).toFixed(0))}
          `
        }
        innerHtml += `
                <span style="font-weight: 600; font-size: 0.8rem; color: ${mediumTint}; margin-left: -0.2rem;">
                  ${this.vitalUnit}
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot style="${titleStyle}">
            <tr>
              <th>
                ${tooltip.dataPoints[0].raw.date}
              </th>
            </tr>
          </tfoot>
        `
        tooltipEl.querySelector('table').innerHTML = innerHtml
      }
      tooltipEl.style.opacity = 1
      tooltipEl.style.position = 'absolute'
      tooltipEl.firstChild.style.borderSpacing = '10px 0'
      tooltipEl.firstChild.style.borderCollapse = 'separate'
      tooltipEl.style.padding = '5px 0'
      tooltipEl.style.background = documentStyle.getPropertyValue('--ion-card-background')
      tooltipEl.style.borderRadius = '10px'
      tooltipEl.style.pointerEvents = 'none'
      tooltipEl.style.boxShadow = `
        rgb(0 0 0 / 20%) 0px 3px 1px -2px,
        rgb(0 0 0 / 14%) 0px 2px 2px 0px,
        rgb(0 0 0 / 12%) 0px 1px 5px 0px
      `
      let leftPosition = position.left + window.pageXOffset + tooltip.caretX - 55
      let maxLeft = position.left + window.pageXOffset + position.width - tooltipEl.offsetWidth
      tooltipEl.style.left = `${Math.max(position.left, Math.min(leftPosition, maxLeft))}px`
      tooltipEl.style.top = `${position.top + window.pageYOffset - tooltipEl.offsetHeight}px`
    },
    render () {
      this.rendering = true
      this.removeTooltip()
      this.chartOptions = {
        responsive: true,
        animation: false,
        scales: {
          y: this.yScales,
          x: this.xScales,
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
          axis: 'x',
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            external: this.customTooltips,
          },
        },
      }
      this.setAggregates()
      let datasets = this.getDatasets()
      this.chartData = {
        datasets: datasets,
      }
      this.$nextTick(() => {
        this.rendering = false
      })
    },
    removeTooltip () {
      this.showTooltip = false
      let tooltipEl = document.getElementById('chartjs-tooltip')
      if (tooltipEl) {
        tooltipEl.remove()
      }
    },
  },
  created () {
    this.render()
  },
  beforeUnmount () {
    this.removeTooltip()
  },
  watch: {
    startDate () {
      let defaultWindow = this.narrowView ? 'Day' : 'Week'
      if (this.timeWindow !== defaultWindow) {
        this.getVitals()
      } else {
        if (!this.rtm) this.$store.dispatch(PATIENT_RPM_VITALS_REQUEST, {params: {mobile: true}}).then(this.render())
        else this.render()
      }
    },
    showTooltip () {
      this.$emit('hideTitle', this.showTooltip)
    },
  },
})
</script>

<style scoped>
.vital-chart {
  margin-left: -13px;
  max-width: calc(100% + 13px) !important;
  max-height: calc(100vh - 345px);
}
.tooltip-vertical-line {
  position: absolute;
  width: 1px;
  background: var(--ion-color-medium-tint);
  pointer-events: none;
}
.tooltip-vertical-line.hide {
  opacity: 0;
}
</style>
