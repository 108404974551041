<template>
  <ion-loading :is-open="loading" message="Loading..." :duration="20000" cssClass="medium-loading-container"
               @didDismiss="loading=false"/>
  <div class="ion-margin-vertical">
    <div class="ion-text-uppercase vitals-alerts-title">
      Alerts
    </div>
    <ion-card class="ion-no-margin ion-margin-vertical">
      <ion-card-content class="ion-no-margin ion-no-padding">
        <ion-list v-if="alerts.length" lines="none" class="vitals-alerts-list">
          <ion-item v-for="alert in alerts" :key="alert.id" class="alert-item">
            <ion-icon slot="start" color="danger" :ios="alertCircle" :md="alertCircle"/>
            <ion-note class="vital-alert-note" color="dark">{{ alert.body }}</ion-note>
          </ion-item>
        </ion-list>
        <ion-list v-else lines="none" class="vitals-alerts-list">
          <ion-item>
            <ion-icon slot="start" color="success" :ios="checkmarkCircle" :md="checkmarkCircle"/>
            <ion-note class="no-alert-title" color="dark">No alerts found.</ion-note>
          </ion-item>
        </ion-list>
        <ion-label></ion-label>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonLoading,
} from '@ionic/vue'
import {defineComponent} from 'vue'
import {mapGetters} from 'vuex'
import {
  warning,
  alertCircle,
  checkmarkCircle,
} from 'ionicons/icons'
import { pathToClinicalIDs } from '../utils/vitals'
import axios from 'axios'
import { dismissElement } from '../utils/overlay'
import {PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST} from "@/store/actions/patient";

export default defineComponent({
  name: 'VitalsAlerts',
  setup() {
    return {
      warning,
      alertCircle,
      checkmarkCircle,
    }
  },
  emits: [],
  props: [
    'vitalType',
    'timeWindow',
    'startDate',
    'endDate',
    'narrowView',
  ],
  components: {
    IonCard,
    IonCardContent,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonLoading,
  },
  data() {
    return {
      loading: false,
      extendedAlerts: [],
      loadingExtendedAlerts: false
    }
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'patientRPMVitalNotifications',
    ]),
    clinicalIDs () {
      return pathToClinicalIDs[this.vitalType]
    },
    alerts () {
      let defaultWindow = this.narrowView ? 'Day' : 'Week'
      if (this.timeWindow === defaultWindow || this.loadingExtendedAlerts) {
        return this.patientRPMVitalNotifications.data[this.vitalType]
      }
      return this.extendedAlerts
    }
  },
  methods: {
    getAlerts() {
      dismissElement('ion-loading')
      this.loadingExtendedAlerts = true
      this.loading = true
      this.extendedAlerts = []
      axios({
        method: 'get',
        url: `/patients/${this.userProfile.role_data.id}/rpm_vital_notifications/`,
        params: {
          clinical_ids: this.clinicalIDs.join(','),
          start: this.startDate.format('YYYY-MM-DD'),
          end: this.endDate.format('YYYY-MM-DD'),
          mobile: true,
        }
      }).then((response) => {
        this.extendedAlerts = response.data
        this.loadingExtendedAlerts = false
        dismissElement('ion-loading')
      })
    }
  },
  watch: {
    startDate () {
      let defaultWindow = this.narrowView ? 'Day' : 'Week'
      if (this.timeWindow !== defaultWindow) {
        this.getAlerts()
      } else {
        this.$store.dispatch(PATIENT_RPM_VITAL_NOTIFICATIONS_REQUEST, {params: {mobile: true}})
      }
    },
  },
})
</script>

<style scoped>

.vitals-alerts-title {
  color: var(--ion-color-medium-tint);
  font-weight: 600;
  font-size: 0.7em;
}
.vitals-alerts-list {
  padding: 1px 0;
}
.vital-alert-note {
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
}
.alert-item {
  margin: 0 3px;
}
.no-alert-title {
  color: var(--ion-color-light-contrast);
  font-weight: 600;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: auto;
}

</style>
